<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="10" class="top_l">
					<el-col :span="20" :xs="24" class="elel">
						<el-button type="primary" @click="dialogAdd = true">新增</el-button>
						<el-button type="primary" @click="clcedit(tableData.levels)">编辑</el-button>
					</el-col>
					<!-- <el-col :span="4" :xs="24" >
						<el-form-item label="选值">
							<el-select v-model="types" placeholder="请选择" @change="SignInConfig">
								<el-option  label="展示" value="show" ></el-option>
								<el-option  label="计算" value="tally" ></el-option>
							</el-select>
						</el-form-item>
					</el-col >-->
				</el-row>
				<div v-if="this.tableData != []">{{tableData}}</div>
				<el-empty v-else :description="emptyText" :image-size="100"></el-empty>
				<!-- 新增-配置 -->
				<el-dialog v-model="dialogAdd" title="新增配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="120px"
						class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="产品ID" prop="levelId">
									<el-input v-model="productId" disabled />
								</el-form-item>
							</el-col>
							<!-- 	<el-col :span="12" :xs="24" >
								<el-form-item label="选值" prop="region">
									<el-select v-model="ruleForm.region" placeholder="请选择">
										<el-option  label="展示" value="show" ></el-option>
										<el-option  label="计算" value="tally" ></el-option>
									</el-select>
								</el-form-item>
							</el-col> -->
							<el-col :span="24">
								<el-form-item label="value值" prop="value"><el-input type="textarea" :rows="12"
										v-model="ruleForm.value"></el-input></el-form-item>
							</el-col>

						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="verification('add')">验证</el-button>
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 修改-配置 -->
				<el-dialog v-model="dialogEdit" title="修改配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="180px"
						class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="产品ID" prop="levelId">
									<el-input v-model="productId" disabled />
								</el-form-item>
							</el-col>
							<!-- 	<el-col :span="12" :xs="24" >
								<el-form-item label="选值" >
									<el-select v-model="typess" placeholder="请选择" @change="typeClk">
										<el-option  label="展示" value="show" ></el-option>
										<el-option  label="计算" value="tally" ></el-option>
									</el-select>
								</el-form-item>
							</el-col> -->
							<el-col :span="24">
								<el-form-item label="value值" prop="value"><el-input type="textarea" :rows="12"
										v-model="EditruleForm.value"></el-input></el-form-item>
							</el-col>

						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="verification('Edit')">验证</el-button>
							<el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
							<el-button @click="dialogEdit = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
	export default {
		name: 'levelConfig',
		data() {
			return {
				fullscreenshow: false,
				emptyText: '暂无数据',
				tableData: [],
				serloading: false,
				productId: '',
				types: 'show',
				typess: '',
				// 新增配置
				dialogAdd: false,
				ruleForm: {
					value: '',
					region: ''
				},
				rules: {
					value: [{
						required: true,
						message: '请输入配置value值',
						trigger: 'blur'
					}],
					region: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
				},
				// 编辑
				dialogEdit: false,
				EditruleForm: {
					value: '',
					region: ''
				}
			};
		},
		created() {
			if (document.body.clientWidth < 768) {
				this.fullscreenshow = true;
			} else {
				this.fullscreenshow = false;
			}
			this.productId = this.$TOOL.data.get('DATA_SELECTEED')
			// 获取配置
			this.SignInConfig();
		},
		watch: {
			'$store.state.dataSelected'(newval) {
				if (this.productId != newval) {
					this.productId = newval
					// 获取配置
					this.SignInConfig();
				}
			}
		},
		methods: {
			// 验证格式
			verification(type) {
				if (this.ruleForm.value == '' && this.EditruleForm.value == '') {

					return this.$message.error('请入内容');
				}
				var objType = {}
				try {
					if (type === 'add') {
						objType = JSON.parse(this.ruleForm.value);
					} else {
						objType = JSON.parse(this.EditruleForm.value);
					}
					if (typeof objType == 'object') {
						this.$message.success('格式正确');
					} else {
						this.$message.error('格式错误');
					}
				} catch (e) {
					this.$message.error('格式错误');
				}


			},
			// 获取配置
			SignInConfig() {
				this.serloading = true;
				this.$HTTP.post(
					'riddle/getRankRewardConfig', {
						productId: this.productId,
						// type: this.types
					},
					response => {
						if (response.status >= 200 && response.status < 300) {
							if (response.data.code == 0) {
								this.tableData = response.data.data;
								this.serloading = false;
							} else {
								this.$message.error(response.data.msg);
							}
						} else {
							console.log(response.message);
						}
					}
				);
			},
			// 重置
			resetClick(name) {
				this.$refs[name].resetFields();
			},
			// 新增-配置
			submitClick(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.$HTTP.post(
							'riddle/addRankRewardConfig', {
								productId: this.productId,
								// type: this.ruleForm.region,
								config: this.ruleForm.value,
							},
							response => {
								if (response.status >= 200 && response.status < 300) {
									if (response.data.code == 0) {
										this.dialogAdd = false;
										this.$message.success(response.data.msg);
										this.resetClick(formName);
										// 获取配置
										this.SignInConfig();
									} else {
										this.$message.error(response.data.msg);
									}
								} else {
									console.log(response.message);
								}
							}
						);
					} else {
						return false;
					}
				});
			},
			// 编辑
			clcedit() {
				this.EditruleForm.value = JSON.stringify(this.tableData);
				// this.typess = this.types
				// this.EditruleForm.region = this.typess
				this.dialogEdit = true;
			},

			typeClk() {
				this.EditruleForm.region = this.typess
			},
			EditsubmitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.$HTTP.post(
							'riddle/updateRankRewardConfig', {
								productId: this.productId,
								config: this.EditruleForm.value,
								// type: this.EditruleForm.region,
							},
							response => {
								if (response.status >= 200 && response.status < 300) {
									if (response.data.code == 0) {
										this.dialogEdit = false;
										this.$message.success(response.data.msg);
										// 获取配置
										this.SignInConfig();
									} else {
										this.$message.error(response.data.msg);
									}
								} else {
									console.log(response.message);
								}
							}
						);
					} else {
						return false;
					}
				});
			},

		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.el-input-number {
		width: 100%;
	}

	.readbox {
		display: inline-block;
		margin-left: 20px;

		span {
			margin-right: 10px;
			font-size: 16px;
		}
	}

	.elel {
		display: flex;
		align-items: center;
	}
</style>